import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import queryString from "query-string"
import "moment-timezone"
import LoadingModal from "../components/Modals/LoadingModal"

/**
 * This component is the landing page comming from CMS gyldendal.no.
 * Store all the params recieved from CMS in sessionstorage.
 * Call IdentityApi to login/SSO and get user info.
 * For local testing see comments below.
 *
 * @version 1.0.1
 * @visibleName Index
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const IndexPage = () => {
  const [loading, setloading] = useState(false)

  useEffect(() => {
    setloading(true)
    let queryParam
    if (location.search !== null) queryParam = queryString.parse(location.search)

    if (queryParam.returnUrl !== undefined) window.sessionStorage.setItem("gyldendal_minside_redirecturl", queryParam.returnUrl)

    //Prøvetilgang: for local testing
    // window.sessionStorage.setItem("gyldendal_minside_productid", "9788205513419")
    // window.sessionStorage.setItem("gyldendal_minside_producturl", "https://www.skolestudio.no/hei/")
    // window.sessionStorage.setItem("gyldendal_minside_expiredate", "2021-03-30T16:03:39")
    // window.sessionStorage.setItem("gyldendal_minside_groupId", "")
    if (queryParam.isbn !== undefined) {
      window.sessionStorage.setItem("gyldendal_minside_productid", queryParam.isbn)
      window.sessionStorage.setItem("gyldendal_minside_expiredate", queryParam.expiresAt)
      window.sessionStorage.setItem("gyldendal_minside_groupid", "")
    }
    if (queryParam.previewUrl !== undefined) {
      window.sessionStorage.setItem("gyldendal_minside_groupid", "")
      window.sessionStorage.setItem("gyldendal_minside_producturl", queryParam.previewUrl)
    }

    //Vurderingseks: for local testing
    // window.sessionStorage.setItem("gyldendal_minside_groupid", "159567")
    // window.sessionStorage.setItem("gyldendal_minside_productid", "")
    if (queryParam.groupId !== undefined) {
      window.sessionStorage.setItem("gyldendal_minside_productid", "")
      window.sessionStorage.setItem("gyldendal_minside_producturl", "")
      window.sessionStorage.setItem("gyldendal_minside_groupid", queryParam.groupId)
    }

    //Get publication Access
    window.sessionStorage.setItem("gyldendal_minside_accountid", "")
    navigate(process.env.GATSBY_API_URL_ID + "/TibetIdentity?redirect_from_tibet=true&productid=" + queryParam.isbn)
  }, [])

  return (
    <>
      {loading ? <LoadingModal /> : null}
      <main>
      </main>
    </>
  )
}

export default IndexPage
